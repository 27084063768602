/* import __COLOCATED_TEMPLATE__ from './setup.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import templateOnlyComponent from '@ember/component/template-only';

export interface Args {}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

const Setup = templateOnlyComponent<Signature>();
export default Setup;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::Setup': typeof Setup;
    'workflow-connector/setup': typeof Setup;
  }
}
